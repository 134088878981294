<template>
	<div>
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="体验点"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		<iframe scrolling="no" style="width: 100vw;min-height: 100vh;" :src="url" frameborder="0"></iframe>
	</div>
</template>
<script>
export default {
	data(){
		return{
			url:''
		}
	},
	mounted(){
		this.url = this.$route.query.url
	},
	methods:{

	}
}
</script>
